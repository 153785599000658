import React, { useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { NavHashLink } from "react-router-hash-link";
// import { Link, NavLink } from "react-router-dom";
import "./SideNav.css";

export default function SideNav() {
  const [show, setShow] = useState(false);
  const closeHandle = (e) => {
    const item = e.target.className;
    // e.preventDefault();
    if (
      item.includes("fas") ||
      item.includes("nav-link") ||
      item.includes("navbar-toggle")
    ) {
      console.log(item);
      setShow(!show);
    }
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <Navbar
      collapseOnSelect
      className='sidenav px-3'
      bg='dark'
      variant='dark'
      expand={false}>
      <Container fluid>
        <Navbar.Brand className='' style={{ width: "6.875rem" }} href='#home'>
          <NavHashLink to='/'>
            <img className='img-fluid' src='/image/logo.png' alt='' srcset='' />
          </NavHashLink>
        </Navbar.Brand>
        <span
          onClick={closeHandle}
          className='fas'
          style={{ cursor: "pointer" }}>
          <i className='fas fa-bars fa-3x text-white'></i>
        </span>
        {/* <Navbar.Toggle aria-controls='offcanvasNavbar' /> */}

        <Navbar.Offcanvas
          onClick={closeHandle}
          scroll={true}
          show={show}
          id='offcanvasNavbar'
          aria-labelledby='offcanvasNavbarLabel'
          placement='end'>
          <Offcanvas.Header className='d-flex justify-content-end'>
            <Navbar.Toggle aria-controls='offcanvasNavbar' />

            <span
              class='navbar-toggler'
              sdata-bs-toggle='offcanvas'
              data-bs-target='#offcanvasNavbar9'
              aria-controls='offcanvasNavbar'>
              <i
                className='fas fa-times fa-2x '
                style={{ cursor: "pointer" }}></i>
            </span>
          </Offcanvas.Header>
          <Offcanvas.Body className='d-flex justify-content-center align-items-center'>
            <div>
              <div className='d-flex justify-content-center align-items-center mb-5'>
                <img src='/image/logo.png' alt='' className='img-fluid w-50' />
              </div>
              <Nav className='d-flex justify-content-center align-items-center'>
                <NavHashLink
                  smooth
                  className='nav-link'
                  to='/#chefschoice'
                  scroll={(el) => scrollWithOffset(el)}>
                  Chef's Choice
                </NavHashLink>
                <NavHashLink
                  scroll={(el) => scrollWithOffset(el)}
                  smooth
                  className='nav-link'
                  to='/#menu'>
                  Menu
                </NavHashLink>
                <NavHashLink
                  scroll={(el) => scrollWithOffset(el)}
                  className='nav-link'
                  to='/#gallary'>
                  Gallery
                </NavHashLink>
                <NavHashLink smooth className='nav-link' to='/ourStory'>
                  Our Story
                </NavHashLink>
                <NavHashLink
                  scroll={(el) => scrollWithOffset(el)}
                  smooth
                  className='m-0 nav-link'
                  to='/#contactUs'>
                  Contact Us
                </NavHashLink>
              </Nav>
              <div className='d-flex justify-content-center mt-5'>
                <span className='me-5'>
                  <a
                    href=' https://www.instagram.com/cafepetriqor/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-white'>
                    <i class='fab fa-instagram fa-3x'></i>
                  </a>
                </span>
                <span className=''>
                  <a
                    href='https://www.facebook.com/cafepetriqor/ '
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-white'>
                    <i className='fab fa-facebook-f fa-3x '></i>
                  </a>
                </span>
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
