import React, { useRef, useState } from "react";
import "./ChefsCarousel.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";

// import "./styles.css";

// import Swiper core and required modules
import SwiperCore, { EffectCards, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([EffectCards]);

export default function ChefsCarousel() {
	return (
		<div class='chefsChoice'>
			<Swiper
				modules={[Navigation]}
				navigation={false}
				effect={"cards"}
				grabCursor={true}
				initialSlide={2}
				className='mySwiper'>
				<SwiperSlide>
					<div className='p-5 text-center'>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/BigBreakfast-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Big Breakfast</h4>
							<p>
								Three eggs | chicken sausage | chicken bacon
								|spinach | onion mushroom | baked beans | toast
								| choice of coffee, tea or juice
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-5 text-center'>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/BeetTheTacos-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Beet the Tacos</h4>
							<p>
								Beetroot flour tortillas | salsa fresca | sour
								cream | choice of filling- corn, cottage cheese
								or chicken
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-5 text-center'>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/GrilledRoulade-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Grilled Roulade</h4>
							<p>
								Herbed rice| rosemary infused potatoes & carrot
								| red wine jus option of chicken or vegetarian
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-5 text-center'>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/khaosuey.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Khao Suey</h4>
							<p className='text-capitalize'>
								noodles | coconut milk | fried garlic | fried
								onion | chillies | cilantro | lemon
							</p>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-5 text-center'>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/FlatbreadPizza-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>
								Fire Roasted Veggies (flatbread pizza)
							</h4>
							<p>
								assorted grilled vegetables | sun-dried tomatoes
								| olives | mozzarella
							</p>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
}
