import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";

import "./OurTeamCarousel.css";

// import required modules
import SwiperCore, { Navigation, EffectCube, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, EffectCube, Pagination]);

export default function App() {
	return (
		<>
			<Swiper
				pagination={{
					clickable: true,
				}}
				// effect={"cube"}
				grabCursor={true}
				className='ourTeamSwiper'>
				<SwiperSlide>
					<div className='p-4 py-5'>
						<div className='px-5'>
							<img src='/image/ourteam.png' alt='' />
						</div>
						<div className='mt-5'>
							<div className='name mb-4'>Mr. Avinash Paleri</div>
							<div className='designation'>Founder</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-4 py-5'>
						<div className='px-5'>
							<img src='/image/ourteam1.png' alt='' />
						</div>
						<div className='mt-5'>
							<div className='name mb-4'>
								Dimple Mahendra Jolapara
							</div>
							<div className='designation'>Co-Founder</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='p-4 py-5'>
						{" "}
						<div className='px-5'>
							<img src='/image/ourteam2.png' alt='' />
						</div>
						<div className='mt-5'>
							<div className='name mb-4'>
								Ketaki Pramod Chandra
							</div>
							<div className='designation'>Co-Founder</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</>
	);
}
