import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import $ from "jquery";

import Banner from "../Banner/Banner";
import ChefsChoice from "../ChefsChoice/ChefsChoice";
import OurMenu from "../ourmenu/OurMenu";
import Gallary from "../Gallary/Gallary";
import { Helmet } from "react-helmet";
import loadjs from "loadjs";

export default function Home() {
	useEffect(() => {
		loadjs("/jquery.rwdImageMaps.js");
		loadjs("/map.js");
	}, []);
	return (
		<div>
			<Banner />
			<ChefsChoice />
			<OurMenu />
			<Gallary />
		</div>
	);
}
