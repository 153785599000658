import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./HeaderWithNavband.css";

export default function HeaderWithNavband() {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand='lg'
        variant='dark'
        className='fixed-top navBar headerWithNavBand w-100'
        style={{ background: "rgb(0,0,0,.6)" }}>
        <Container fluid className=''>
          <Navbar.Brand className='' style={{ width: "6.875rem" }} href='#home'>
            <Link to='/'>
              <img
                className='img-fluid'
                src='/image/logo.png'
                alt=''
                srcset=''
              />
            </Link>
          </Navbar.Brand>
          <div className=''>
            <Navbar.Toggle
              aria-controls='responsive-navbar-nav'
              className='text-white border'
            />
          </div>
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mx-auto'>
              <HashLink smooth className='nav-link' to='/#chefschoice'>
                Chef's Choice
              </HashLink>
              <HashLink smooth className='nav-link' to='/#menu'>
                Menu
              </HashLink>
              <HashLink smooth className='nav-link' to='/#gallary'>
                Gallery
              </HashLink>
              <NavLink smooth className='nav-link' to='/ourStory'>
                Our Story
              </NavLink>
              <HashLink smooth className='nav-link' to='/#contactUs'>
                Contact Us
              </HashLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
