import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./OurMenu.css";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import OverlayMenu from "./Overlay_Menu/OverlayMenu";

export default function OurMenu() {
	const [isOpen, setOpen] = useState(false);
	const [Overlay_show, setOverlay_show] = useState(false);
	const [itemName, setItemName] = useState("");
	const areaClicks = () => {
		$("area").on("click", function (event) {
			event.preventDefault();
			const item = $(this).attr("alt");
			setItemName(item);
			// alert($(this).attr("alt") + " clicked");
			setOverlay_show(true);
		});
	};
	useEffect(() => {
		areaClicks();
	}, [Overlay_show]);

	return (
		<div id='menu' className='ourmenu'>
			<div className='d-flex justify-content-center  my-4'>
				<h3>Our Menu</h3>
			</div>
			<div className='position-relative'>
				<img
					src='/image/ourmenu.png'
					className='img-fluid'
					alt=''
					usemap='#image-map'
				/>
				{Overlay_show ? (
					<OverlayMenu
						Overlay_show={setOverlay_show}
						item={itemName}
					/>
				) : (
					""
				)}
				<map name='image-map'>
					<area
						// target='_blank'
						alt='hot beverages'
						title='hot beverages'
						href=''
						coords='51,203,286,433'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='cold beverages'
						title='cold beverages'
						href=''
						coords='384,64,633,349,575,277'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='shakes'
						title='shakes'
						href=''
						coords='724,180,873,504'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='hearty breakfast'
						title='hearty breakfast'
						href=''
						coords='982,102,1269,353'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='soups'
						title='soups'
						href=''
						coords='1376,233,1550,453'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='salads'
						title='salads'
						href=''
						coords='1634,55,1871,325'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='starters'
						title='starters'
						href=''
						coords='93,569,280,844'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='Grub hub'
						title='Grub hub'
						href=''
						coords='382,515,599,724'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='mains'
						title='mains'
						href=''
						coords='706,620,956,850'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='steak'
						title='steak'
						href=''
						coords='1035,461,1270,683'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='flatbread pizza'
						title='flatbread pizza'
						href=''
						coords='1335,596,1603,825'
						shape='rect'
					/>
					<area
						// target='_blank'
						alt='desserts'
						title='desserts'
						href=''
						coords='1692,454,1832,680'
						shape='rect'
					/>
				</map>
			</div>
			<div className='position-relative'>
				<span
					className='position-absolute  play-btn'
					onClick={() => setOpen(true)}>
					<i class='fas fa-play-circle'></i>
				</span>
				<img
					className='img-fluid'
					src='/image/videoThumbnile.png'
					alt=''
					srcset=''
				/>
			</div>
			<ModalVideo
				channel='custom'
				autoplay
				isOpen={isOpen}
				url='https://cafepetriqor.s3.ap-south-1.amazonaws.com/cafe_petriqor_video.mp4'
				onClose={() => setOpen(false)}
			/>
		</div>
	);
}
