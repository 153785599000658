import React from "react";
import "./Banner.css";
import Header from "../Header/Header";
import useWindowResize from "../../CustomHooks/useWindowResize";
export default function Banner() {
	const { width, height } = useWindowResize();

	return (
		<div className='banner p-4'>
			{width > 990 ? <Header /> : ""}

			<div className='mt-5 d-flex flex-column align-items-center'>
				<div className='' style={{ width: "24rem" }}>
					<img
						className='img-fluid'
						src='/image/logo.png'
						alt=''
						srcset=''
					/>
				</div>
				<div className='d-flex justidy-content-center align-items-center flex-column py-5'>
					<p className='text-center cafeSubTitle'>
						Cafe Petriqor is a space that brings back memories of&nbsp;
						<br className="d-none d-sm-inline-block" />
						 the first rains and a sense of belonging.
					</p>
					<a
						href='#menu'
						className='btn btn-outline-secondary px-4 py-2'>
						CHECK OUR MENU
					</a>
				</div>
			</div>
		</div>
	);
}
