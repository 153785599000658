import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./Header.css";

export default function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      className=' navBar header w-100'
      style={{ background: "transparent" }}>
      <Container className=''>
        <div className='ms-auto mb-3'>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className='text-white border'
          />
        </div>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mx-md-auto'>
            <HashLink smooth className='nav-link' to='/#chefschoice'>
              Chef's Choice
            </HashLink>
            <HashLink smooth className='nav-link' to='/#menu'>
              Menu
            </HashLink>
            <HashLink smooth className='nav-link' to='/#gallary'>
              Gallery
            </HashLink>
            <NavLink smooth className='nav-link' to='/ourStory'>
              Our Story
            </NavLink>
            <HashLink smooth className='m-0 nav-link' to='/#contactUs'>
              Contact Us
            </HashLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
