import React from "react";
// import InstagramEmbed from "react-instagram-embed";
import "./Gallary.css";
import GallaryCarousel from "./GallaryCarousel/GallaryCarousel";

export default function Gallary() {
  return (
    <div id='gallary' className='gallary my-5'>
      <div className='text-center mb-5'>
        <h3 className='title '>Our Gallery</h3>
        <p className='sub-title'>a GLIMPSE OF OUR CAFE</p>
      </div>
      {/* <div className='d-md-none mb-5'>
        <GallaryCarousel />
      </div> */}
      <div
        class='tagembed-container px-5'
        style={{ width: "100%", height: "100%", overflow: "auto" }}>
        <div
          class='tagembed-socialwall'
          data-wall-id='40899'
          view-url='https://widget.tagembed.com/40899?view'>
          {" "}
        </div>{" "}
      </div>
      <div className='px-5 d-md-none d-none'>
        <div className='d-flex flex-wrap img-wrapper'>
          <div className='col-md-4 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary1.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
          <div className='col-md-5 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary2.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
          <div className='col-md-3 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary3.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
        </div>
        <div className='d-flex flex-wrap img-wrapper'>
          <div className='col-md-3 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary4.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
          <div className='col-md-3 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary5.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
          <div className='col-md-3 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img'
                src='/image/gallary6.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
          <div className='col-md-3 col-12 p-2'>
            <div>
              <img
                className='img-fluid food-img '
                src='/image/gallary7.png'
                alt=''
                srcset=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
