import React, { useState, useEffect } from "react";
// import { Next, Prev } from "react-bootstrap/esm/PageItem";
import "./Overlay_Menu.css";

export default function OverlayMenu({ Overlay_show, item }) {
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(item);
	const [Keys, setKeys] = useState([]);
	const [Next, setNext] = useState(null);
	const [Prev, setPrev] = useState(null);
	const [overlay, setOverlay] = useState("");

	const NextButton = () => {
		setSelected(Keys[Next]);
	};
	const PrevButton = () => {
		setSelected(Keys[Prev]);
	};

	const PrevNext = (index, length) => {
		console.log(index, length);
		const next = (index + 1) % length;
		setNext(next);
		const prev = index > 0 ? index - 1 : length - 1;
		setPrev(prev);
	};
	useEffect(() => {
		fetch("/menu.json")
			.then((res) => res.json())
			.then((data) => {
				const result = data[0];
				const keys = Object.keys(result);
				setKeys(keys);
				// console.log(result);
				setOverlay(`/image/${selected}.png`);

				setData(result[selected]);
				console.log(Object.keys(result).indexOf(selected));
				const index = keys.indexOf(selected);
				PrevNext(index, keys.length);
			});
	}, [selected]);
	// console.log("data", Next, Prev);
	return (
		<div
			className='position-absolute img-fluid overlayImage  h-100 w-100 px-4 py-2 py-md-4 text-white'
			style={{
				backgroundImage: "url('/image/overlayBG.png')",
				zIndex: "500",
			}}>
			<div className='d-flex justify-content-end'>
				<span
					onClick={() => Overlay_show(false)}
					className='closeBtn'
					style={{ cursor: "pointer" }}>
					<i class='fas fa-times'></i>
				</span>
			</div>
			<div className='d-flex flex-wrap position-relative h-100'>
				<div className='col-3 '>
					<img
						src={overlay}
						style={{
							left: "-3rem",
							top: "50%",
							transform: "translateY(-55%)",
						}}
						className='img-fluid position-absolute item_logo'
						alt=''
						srcset=''
					/>
				</div>
				<div className='col-9 d-flex flex-column '>
					<div
						className='d-flex w-100 align-items-center'
						style={{ height: "fit-content" }}>
						<span
							className='text-white d-inline-block title text-capitalize me-3 me-md-5'
							style={{
								width: "max-content",
								whiteSpace: "nowrap",
							}}>
							{selected}
						</span>
						<hr
							className=''
							style={{ height: "2px", width: "100%" }}
						/>
					</div>
					<div className=''>
						<div className='d-flex '>
							<div className='col-6 ps-md-5 ps-3'>
								{data.slice(0, 8).map((item, ind) => (
									<p className='menuItems'>{item.name}</p>
								))}
							</div>
							{data.length > 8 ? (
								<div
									className='col-6 ps-md-5 ps-3'
									style={{ borderLeft: "2px solid #d3d3d3" }}>
									{data.slice(8, 16).map((item, ind) => (
										<p className='menuItems'>{item.name}</p>
									))}
								</div>
							) : (
								""
							)}
						</div>
					</div>
					<div className='d-flex justify-content-end mb-md-3 mt-auto'>
						<span
							className='me-3 arrowBtn'
							style={{ cursor: "pointer" }}
							onClick={PrevButton}>
							<h4 className='text-center text-capitalize arrowItem'>
								{Keys[Prev]}
							</h4>
							<img
								src='/image/leftarrow.png'
								className=''
								alt=''
								srcset=''
							/>
						</span>
						<span
							onClick={NextButton}
							className='arrowBtn'
							style={{ cursor: "pointer" }}>
							<h4 className='text-center text-capitalize arrowItem'>
								{Keys[Next]}
							</h4>
							<img
								src='/image/rightarrow.png'
								className=''
								alt=''
								srcset=''
							/>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
