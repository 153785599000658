import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./GallaryCarousel.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";

// import "./styles.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, EffectCube, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, EffectCube, Pagination]);

export default function GallaryCarousel() {
  return (
    <div className='gallary'>
      <Swiper
        pagination={{
          clickable: true,
        }}
        effect={"cube"}
        grabCursor={true}
        className='mySwiper'
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.5,
        }}>
        <SwiperSlide>
          <div className='col-md-4 col-12'>
            <img
              className='img-fluid'
              src='/image/gallary3.png'
              alt=''
              srcset=''
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='col-md-4 col-12'>
            <img
              className='img-fluid'
              src='/image/gallary4.png'
              alt=''
              srcset=''
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='col-md-4 col-12'>
            <img
              className='img-fluid'
              src='/image/gallary5.png'
              alt=''
              srcset=''
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='col-md-4 col-12'>
            <img
              className='img-fluid'
              src='/image/gallary6.png'
              alt=''
              srcset=''
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='col-md-4 col-12'>
            <img
              className='img-fluid'
              src='/image/gallary7.png'
              alt=''
              srcset=''
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
