import React from "react";
import "./Footer.css";

export default function Footer() {
	return (
		<div id='contactUs' className=' text-white footer '>
			<div className='d-flex flex-wrap footer-info'>
				<div className='col-12 col-md-4 pt-5 pb-1 ps-5 '>
					<div className='w-50'>
						<img
							className='img-fluid'
							src='/image/logo.png'
							alt=''
							srcset=''
						/>
					</div>
					<div className='d-flex flex-column info mt-4'>
						<span>602, 15th Cross Rd,</span>{" "}
						<span>Jeewan Sathi Colony,</span>{" "}
						<span>1st Phase, J. P. Nagar,</span>
						<span>Bengaluru, Karnataka 560078</span>
					</div>
				</div>
				<div className='col-12 col-md-4 pt-5 pb-1 ps-5 ps-md-0'>
					<h3 className='mb-4 title'>Opening Hours</h3>
					<div className='info'>
						<p className='mb-4'>All Week: 10:00am - 10:30pm</p>

						{/* <p className='mb-4'>Monday - Friday: 8:00am - 21:00pm</p> */}
					</div>
				</div>

				<div className='col-12 col-md-4 pt-5 pb-5 ps-5 ps-md-0'>
					<h3 className='mb-4 title'>Contact Info </h3>
					<div className='info'>
						<p className='mb-4'>
							<span>
								<i className='fas fa-phone-alt me-3'></i>
							</span>
							<span>
								<a
									target='_blank'
									rel='noreferrer'
									className='text-decoration-none text-white'
									href='tel:+916366560050'>
									+91-6366560050
								</a>
							</span>
						</p>
						<p className='mb-4'>
							<i className='fas fa-envelope me-3'></i>
							<span>
								<a
									target='_blank'
									rel='noreferrer'
									href='mailto:cafepetriqor@gmail.com'
									className='text-decoration-none text-lowercase text-white'>
									cafepetriqor@gmail.com
								</a>
							</span>
						</p>
					</div>
					<div>
						<span className='me-3'>
							<a
								href=' https://www.instagram.com/cafepetriqor/'
								target='_blank'
								rel='noopener noreferrer'
								className='text-white'>
								<i class='fab fa-instagram fa-2x social-icon'></i>
							</a>
						</span>
						<span className='me-3'>
							<a
								href='https://www.facebook.com/cafepetriqor/ '
								target='_blank'
								rel='noopener noreferrer'
								className='text-white'>
								<i className='fab fa-facebook-f fa-2x social-icon'></i>
							</a>
						</span>
					</div>
				</div>
			</div>
			<div className='apps d-flex justify-content-md-end justify-content-between align-items-center  px-5 py-2'>
				<div
					className='me-md-5 title pt-3 pb-2'
					style={{ whiteSpace: "nowrap" }}>
					Find us on
				</div>
				<div className=''>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.zomato.com/bangalore/cafe-petriqor-jp-nagar-bangalore'>
						<img
							className='img-fluid app '
							src='/image/zomato.png'
							alt=''
							srcset=''
						/>
					</a>
				</div>
				<div className=''>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.swiggy.com/restaurants/cafe-petriqor-jp-nagar-bangalore-466404'>
						<img
							className='img-fluid app ms-md-4'
							src='/image/swiggy.png'
							alt=''
							srcset=''
						/>
					</a>
				</div>
			</div>
		</div>
	);
}
