import React from "react";
import "./ChefsChoice.css";
import ChefsCarousel from "./ChefsCarousel/ChefsCarousel";

export default function ChefsChoice() {
	return (
		<div className='chefschoice overflow-hidden p-5 d-flex flex-column justify-content-center align-items-center'>
			<div className='text-center my-5 vw-100'>
				<h3 id='chefschoice' className='title'>
					Chef’s Choice
				</h3>
				<span className='sub-title'>Check our highlights</span>
			</div>
			<div className='d-md-none '>
				<div>
					<ChefsCarousel />
				</div>
			</div>
			<div className=' w-100 d-md-flex d-none flex-wrap '>
				<div className='col-md-6 col-lg-3 text-center p-4 '>
					<dxiv>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/BigBreakfast-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Big Breakfast</h4>
							<p>
								Three eggs | chicken sausage | chicken bacon
								|spinach | onion mushroom | baked beans | toast
								| choice of coffee, tea or juice
							</p>
						</div>
					</dxiv>
				</div>
				<div className='col-md-6 col-lg-3 text-center p-4 '>
					<div>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/BeetTheTacos-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Beet the Tacos</h4>
							<p>
								Beetroot flour tortillas | salsa fresca | sour
								cream | choice of filling- corn, cottage cheese
								or chicken
							</p>
						</div>
					</div>
				</div>
				<div className='col-md-6 col-lg-3 text-center p-4 '>
					<div>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/GrilledRoulade-min.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Grilled Roulade</h4>
							<p>
								Herbed rice| rosemary infused potatoes & carrot
								| red wine jus option of chicken or vegetarian
							</p>
						</div>
					</div>
				</div>
				<div className='col-md-6 col-lg-3 text-center p-4 '>
					<div>
						<div className='mb-3'>
							<img
								className='img-fluid'
								src='/image/khaosuey.png'
								alt=''
								srcset=''
							/>
						</div>
						<div>
							<h4 className='mb-3 food-title'>Khao Suey</h4>
							<p className='text-capitalize'>
								noodles | coconut milk | fried garlic | fried
								onion | chillies | cilantro | lemon
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
