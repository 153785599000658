import logo from "./logo.svg";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./App.css";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import OurStory from "./components/ourStory/OurStory";
import SideNav from "./components/Header/sideNav/SideNav";
import useWindowResize from "./CustomHooks/useWindowResize";
import { Helmet } from "react-helmet";

function App() {
	const { width, height } = useWindowResize();

	return (
		<Router>
			{width < 991 ? <SideNav /> : ""}
			<Switch>
				<Route exact path='/'>
					<Home />
				</Route>
				<Route exact path='/ourStory'>
					<OurStory />
				</Route>
			</Switch>

			<Helmet>
				<script
					src='//widget.tagembed.com/embed.min.js'
					type='text/javascript'
				/>
				
			</Helmet>
			<Footer />
		</Router>
	);
}

export default App;
