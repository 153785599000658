import React, { useEffect, useState } from "react";
import useWindowResize from "../../CustomHooks/useWindowResize";
import HeaderWithNavband from "../Header/HeaderWithNavBand/HeaderWithNavband";
import SideNav from "../Header/sideNav/SideNav";
import "./OurStory.css";
import OurTeamCarousel from "./ourTeamcarousel/OurTeamCarousel";

export default function OurStory() {
	const { width, height } = useWindowResize();
	return (
		<div className='ourStory'>
			{width > 990 ? <HeaderWithNavband /> : ""}
			<div>
				{width <= 600 ? (
					<img
						className='img-fluid'
						src='/image/ourstoryMobile.png'
						alt=''
						srcset=''
					/>
				) : (
					<img
						className='img-fluid'
						src='/image/ourStory.png'
						alt=''
						srcset=''
					/>
				)}
			</div>
			<div className='my-5'>
				<h3
					className='title text-center'
					style={{ margin: "5rem 0 0" }}>
					Our Story
				</h3>
				<div className='d-flex flex-wrap py-0 pb-0'>
					<div className='col-md-6 col-12 p-5 texts'>
						<span>
							We conceived the idea of our Café in 2020, with the
							goal of providing a haven for Bangalore's growing
							population to escape the hustle and bustle of daily
							life and enjoy soul-satisfying food in a casual and
							inviting environment.{" "}
						</span>
						<br />{" "}
						<span className='mt-5'>
							Café Petriqor is nestled off the shoulder of a busy
							main road, a quaint, idyllic spot that charms and
							entices anyone looking for a cosy corner to sip on
							some great coffee and grab a bite, have a quick
							meal, hangout with friends, or simply work from a
							pleasant café.
						</span>
					</div>
					<div className='col-md-6 col-12 '>
						<div className='d-flex justify-content-center'>
							<img
								className='img-fluid'
								src='/image/ourstory1.png'
								alt=''
							/>
						</div>
					</div>
				</div>
				<div className='d-flex flex-wrap py-0'>
					<div className='col-md-6 col-12 d-md-block d-none'>
						<div className='d-flex justify-content-center'>
							<img
								className='img-fluid'
								src='/image/ourstory2.png'
								alt=''
							/>
						</div>
					</div>
					<div className='col-md-6 col-12 p-5 texts'>
						<span>
							Our commitment to innovation drives us to constantly
							update our menu with a diverse selection of
							scrumptious and fresh meals.
						</span>
						<br />
						<div className='col-12 d-md-none d-block'>
							<div className='d-flex justify-content-center'>
								<img
									className='img-fluid'
									src='/image/ourstory2.png'
									alt=''
								/>
							</div>
						</div>
						<span className='mt-5'>
							Our mission is to provide a Good Life for every team
							member who joins the family. You are a part of a
							welcoming, collaborative environment that values
							teamwork above all else and continues to innovate
							and question the norm in the restaurant industry,
							providing an exhilarating experience for both the
							staff and our guests.
						</span>
						<span className='welcome mt-5'>
							Hello and welcome to Cafe Petriqor.
						</span>
					</div>
				</div>
			</div>
			<div className='ourTeam my-5'>
				<h3
					className='title text-center'
					style={{ margin: "7rem 0 3rem 0" }}>
					Our Team
				</h3>
				<div className='d-md-none d-block'>
					<OurTeamCarousel />
				</div>
				<div className='d-md-flex d-none flex-wrap justify-content-center text-center px-md-5'>
					<div className='mx-3 my-5'>
						<img src='/image/ourteam.png' alt='' />
						<div className='mt-5'>
							<div className='name mb-4'>Avinash</div>
							<div className='designation'>Founder</div>
						</div>
					</div>
					<div className='mx-5 my-5'>
						<img src='/image/ourteam1.png' alt='' />
						<div className='mt-5'>
							<div className='name mb-4'>Dimple</div>
							<div className='designation'>Co-Founder</div>
						</div>
					</div>
					<div className='mx-3 my-5'>
						<img src='/image/ourteam2.png' alt='' />
						<div className='mt-5'>
							<div className='name mb-4'>Ketaki</div>
							<div className='designation'>Co-Founder</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
